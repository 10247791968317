import React from 'react'
import { OUTLETS } from '@shopper/app/utils/helpers'
import { Circle, XStack } from '@shopper/ui/src'
import { Link } from 'solito/link'
import PATHS from '@centrito/common/paths'

interface OutletsGridProps {
  columns?: number
  gap?: number
}

export const OutletsGrid: React.FC<OutletsGridProps> = ({ columns = 4, gap = 10 }) => {
  return (
    <XStack
      flexWrap="wrap"
      gap={gap}
      paddingHorizontal={10}
      paddingBottom={10}
      $platform-web={{
        display: 'grid',
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
      }}
    >
      {OUTLETS.filter((outlet) => outlet.isActive).map((outlet) => (
        <Link
          key={outlet.id}
          href={{
            pathname: PATHS.Products.Outlets,
            query: {
              outletId: outlet.id,
            },
          }}
        >
          <Circle
            aspectRatio={1}
            backgroundColor="$pureWhite"
            padding={15}
            borderWidth={1}
            borderColor="$neutral200"
          >
            <outlet.logo />
          </Circle>
        </Link>
      ))}
    </XStack>
  )
}
