import React from 'react'
import { YStack } from '@shopper/ui/src'

interface FreeProductsSectionFrameProps {
  children: React.ReactNode
  onPress: () => void
}

export const FreeProductsSectionFrame: React.FC<FreeProductsSectionFrameProps> = ({
  children,
  onPress,
}) => {
  return (
    <YStack
      width="100%"
      paddingTop={15}
      paddingBottom={5}
      paddingHorizontal={15}
      onPress={onPress}
      hoverStyle={{
        cursor: 'pointer',
      }}
    >
      <YStack
        width="100%"
        backgroundColor="$baseWhite"
        borderRadius={10}
        paddingHorizontal={15}
        paddingVertical={15}
        gap={5}
      >
        {children}
      </YStack>
    </YStack>
  )
}
