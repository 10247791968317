import React, { useContext, useEffect, useState } from 'react'
import { SubhomeScreenContext } from '@shopper/app/features/home/context'
import { SubhomeKey } from '@shopper/app/features/home/context/types'
import { useCategoryNavigation } from '@shopper/app/hooks/useCategoryNavigation'
import useWindowDimensions from '@shopper/app/utils/hooks/useWindowDimensions'
import posthogClient from '@shopper/app/utils/services/analytics/posthog'
import { Spinner, Stack, XStack, YStack } from '@shopper/ui/src'
import { CircleFeedButton } from '@shopper/ui/src/components/button/CircleFeedButton'
import { SeeMoreButton } from '@shopper/ui/src/components/button/SeeMoreButton'
import { ContentBoldText } from '@shopper/ui/src/components/text/new'
import isNil from 'lodash.isnil'
import categoryDisplayLocalData from '@centrito/common/jsons/category_display_local_data.json'
import PATHS from '@centrito/common/paths'
import type { CategoryPublic } from '@centrito/api/nest/platform/database/domain'
import { CategoryScrollView } from './CategoryScrollView'
import { DisplayType } from './utils/constants'

interface CategoryDisplayProps {
  subhome: string
}

export const CategoryDisplay: React.FC<CategoryDisplayProps> = ({ subhome }) => {
  const { handleCategoryPress } = useCategoryNavigation()
  const [displayType, setDisplayType] = useState<DisplayType>(DisplayType.SMALL_RECTANGLE)
  const [topElements, setTopElements] = useState<CategoryPublic[]>([])
  const [emptyElements, setEmptyElements] = useState<number>(0)
  const [bottomElements, setBottomElements] = useState<CategoryPublic[]>([])
  const [isScrollView, setIsScrollView] = useState<boolean>(true)
  const { width: _windowWidth } = useWindowDimensions()
  const displayItems = Math.round(_windowWidth / 85)
  const { categories } = useContext(SubhomeScreenContext)

  const [categoriesToUse, setCategoriesToUse] = useState<CategoryPublic[]>([])
  useEffect(() => {
    if (!isNil(subhome)) {
      // For root subhome, use local data if no categories available yet
      if (subhome === '/') {
        setCategoriesToUse(categories[subhome] || categoryDisplayLocalData)
      } else {
        setCategoriesToUse(categories[subhome])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subhome, categories])

  useEffect(() => {
    if (!isNil(categoriesToUse)) {
      if (categoriesToUse.length > 6) {
        setDisplayType(DisplayType.CIRCLE)
        if (categoriesToUse.length >= 10) {
          setIsScrollView(true)
          const middleIndex = Math.floor(categoriesToUse.length / 2)
          if (categoriesToUse.length % 2 === 0) {
            setTopElements(categoriesToUse.slice(0, categoriesToUse.length / 2))
            setBottomElements(categoriesToUse.slice(categoriesToUse.length / 2))
          } else {
            setTopElements(categoriesToUse.slice(0, middleIndex + 1))
            setBottomElements(categoriesToUse.slice(middleIndex + 1))
          }
        } else {
          const totalElements = categoriesToUse.length - displayItems
          if (totalElements > displayItems) {
            setIsScrollView(true)
            setTopElements(categoriesToUse.slice(0, totalElements))
            setBottomElements(categoriesToUse.slice(totalElements))
          } else {
            setIsScrollView(false)
            setTopElements(categoriesToUse.slice(0, displayItems))
            setBottomElements(categoriesToUse.slice(displayItems))
          }
        }
      } else if (categoriesToUse.length >= 4 && categoriesToUse.length <= 6) {
        setIsScrollView(true)
        setDisplayType(DisplayType.SMALL_RECTANGLE)
      } else {
        setIsScrollView(true)
        setDisplayType(DisplayType.LARGE_RECTANGLE)
      }
    }
  }, [categoriesToUse, displayItems])

  useEffect(() => {
    const topElementsLength =
      subhome === SubhomeKey.MAIN ? topElements.length + 1 : topElements.length
    setEmptyElements(topElementsLength - bottomElements.length)
  }, [bottomElements, topElements, subhome])

  if (isNil(categoriesToUse) || categoriesToUse.length === 0) {
    return (
      <XStack width="100%" height={180} justifyContent="center" alignItems="center">
        <Spinner size="large" color="$samsamBlue" />
      </XStack>
    )
  }

  const handleSeeMorePress = (): void => {
    posthogClient.captureCustomEvent('shopper_subhome_category_see_all_pressed', {
      subhome,
    })
  }

  return (
    <YStack
      marginVertical={10}
      backgroundColor="$baseWhite"
      marginHorizontal={15}
      borderRadius={10}
    >
      <XStack
        justifyContent="space-between"
        alignItems="center"
        marginBottom={10}
        paddingHorizontal={12}
        paddingVertical={12}
      >
        <ContentBoldText paddingTop={4}>Categorías</ContentBoldText>
        <XStack>
          <SeeMoreButton
            text="Ver todo"
            color="$primary1000"
            categoryNodesPrefix={subhome === '/' ? undefined : subhome}
            onPress={handleSeeMorePress}
          />
        </XStack>
      </XStack>

      {isScrollView && (
        <XStack paddingRight={12}>
          <CategoryScrollView
            displayType={displayType}
            bottomElements={bottomElements}
            topElements={topElements}
            categoriesToUse={categoriesToUse}
            emptyElements={emptyElements}
            subhome={subhome}
          />
        </XStack>
      )}
      {displayType === DisplayType.CIRCLE && !isScrollView && (
        <YStack paddingBottom={20} paddingLeft={8} paddingRight={8}>
          <YStack gap="$2">
            <XStack gap="$3">
              {topElements.map((category) => (
                <CircleFeedButton
                  key={`category-browser--${category.nodes}`}
                  imageSrc={category.assets.tab || ''}
                  imageAlt={category.nodes}
                  label={category.nodesNamed.split('-').pop() || ''}
                  onPress={(): void => handleCategoryPress(category, subhome)}
                  href={{
                    pathname: PATHS.Products.ListByCategoryPrefix,
                    query: {
                      categoryNodesPrefix: category.nodes,
                    },
                  }}
                />
              ))}
            </XStack>
            <XStack gap="$3">
              {bottomElements.map((category) => (
                <CircleFeedButton
                  key={`category-browser--${category.nodes}`}
                  imageSrc={category.assets.tab || ''}
                  imageAlt={category.nodes}
                  label={category.nodesNamed.split('-').pop() || ''}
                  onPress={(): void => handleCategoryPress(category, subhome)}
                  href={{
                    pathname: PATHS.Products.ListByCategoryPrefix,
                    query: {
                      categoryNodesPrefix: category.nodes,
                    },
                  }}
                />
              ))}
              {Array.from({ length: emptyElements }).map((_, index) => (
                <Stack key={`empty-element-${index}`} width={72} flexShrink={1} />
              ))}
            </XStack>
          </YStack>
        </YStack>
      )}
    </YStack>
  )
}
