import React, { useContext } from 'react'
import useKeyboardVisibility from '@shopper/app/hooks/useKeyboardVisibility'
import { usePathname } from '@shopper/app/hooks/usePathname'
import { AppStateContext } from '@shopper/app/provider/shopper/appState'
import { useSelector } from '@shopper/app/store'
import { selectCartItems } from '@shopper/app/store/selectors'
// import {HeartTabBar} from '@shopper/ui/src/components/svgs/assets'
import { Circle, Stack, XStack, YStack, isClient } from '@shopper/ui/src'
import {
  CartTabBar,
  FullSearch,
  HomeTabBar,
  UserTabBar,
} from '@shopper/ui/src/components/svgs/assets'
// import HeartSvg from '@shopper/ui/src/components/svgs/assets/HeartTabBar'
import { DefaultTextPrimary } from '@shopper/ui/src/components/text'
// import UserSvg from '@shopper/ui/src/components/svgs/assets/UserTabBar'
import { DetailText3 } from '@shopper/ui/src/components/text/new/detail/DetailText3'
import { MOBILE_WIDTH_STYLES } from '@shopper/ui/src/constants'
import { Search } from '@tamagui/lucide-icons'
import { isNil } from 'lodash'
import { Link } from 'solito/link'
import PATHS from '@centrito/common/paths'

const NAVBAR_ITEMS = [
  {
    Icon: HomeTabBar,
    label: 'Home',
    pathname: PATHS.Site.Home,
  },
  {
    Icon: Search,
    label: 'Categorías',
    pathname: PATHS.Site.Categories,
  },
  {
    Icon: CartTabBar,
    label: 'Carrito',
    pathname: PATHS.Checkout.OrderCart,
  },
  {
    Icon: UserTabBar,
    label: 'Yo',
    pathname: PATHS.Account.Profile,
  },
  // {
  //   Icon: HeartTabbar,
  //   label: 'Favoritos',
  //   pathname: PATHS.Checkout.OrderCart,
  // },
]

const ShopperTabBar: React.FC = () => {
  const currentPathname = usePathname()
  const isKeyboardVisible = useKeyboardVisibility()
  const { setIsScrollingToTop } = useContext(AppStateContext)
  const cartItems = useSelector(selectCartItems)
  const numCartItems = cartItems.reduce((total, cartItem) => total + cartItem.cartItem.quantity, 0)

  if (isKeyboardVisible) return null

  return (
    <XStack
      justifyContent="space-around"
      alignItems="center"
      backgroundColor="$pureWhite"
      borderTopWidth={1}
      borderTopColor="#15224f0d"
      paddingBottom={4}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      position={isClient ? 'fixed' : 'absolute'}
      bottom={0}
      height={65}
      zIndex={3}
      {...MOBILE_WIDTH_STYLES}
    >
      {NAVBAR_ITEMS.map(({ Icon, label, pathname }) => {
        const isCurrent = isNil(currentPathname)
          ? false
          : pathname === currentPathname ||
            (pathname.includes('home') && currentPathname.includes('home'))
        const isHome = pathname.includes('home')
        const isCart = label === 'Carrito'
        const isSearch = label === 'Categorías'
        const color = isCurrent ? '#4646F5' : '#7F7F7F'
        const strokeWidth = isCurrent ? 0 : 2
        const fillColor = isCurrent ? '#4646F5' : 'transparent'
        const backgroundColor = isCurrent ? '$samsamBlue' : 'transparent'

        if (isHome && isCurrent) {
          return (
            <Stack key={label + pathname} onPress={(): void => setIsScrollingToTop(true)}>
              <YStack alignItems="center" justifyContent="center" width={80} height={50}>
                <YStack
                  position="absolute"
                  top={0}
                  right={0}
                  bottom={0}
                  left={0}
                  backgroundColor={backgroundColor}
                  borderRadius={12}
                  opacity={0.2}
                />
                <Icon fill={fillColor} stroke={color} strokeWidth={strokeWidth} />
                <DetailText3 fontSize={10} color={color} marginTop={1}>
                  {label}
                </DetailText3>
              </YStack>
            </Stack>
          )
        }

        return (
          <Link key={label + pathname} href={pathname}>
            <YStack alignItems="center" justifyContent="center" width={80} height={50}>
              <YStack
                position="absolute"
                top={0}
                right={0}
                bottom={0}
                left={0}
                backgroundColor={backgroundColor}
                borderRadius={12}
                opacity={0.2}
              />
              {isCart ? (
                <React.Fragment>
                  <Circle
                    backgroundColor="$samsamBlue"
                    position="absolute"
                    size={14}
                    top={2}
                    right={21}
                    justifyContent="center"
                    alignItems="center"
                    zIndex={999_999}
                  >
                    <DefaultTextPrimary color="white" fontSize={11}>
                      {numCartItems}
                    </DefaultTextPrimary>
                  </Circle>

                  <Icon stroke={color} />
                </React.Fragment>
              ) : isSearch ? (
                isCurrent ? (
                  <FullSearch color={color} />
                ) : (
                  <Search color={color} size={20} strokeWidth={2} />
                )
              ) : (
                <Icon fill={fillColor} stroke={color} strokeWidth={strokeWidth} />
              )}
              <DetailText3 fontSize={10} color={color} marginTop={1}>
                {label}
              </DetailText3>
            </YStack>
          </Link>
        )
      })}
    </XStack>
  )
}

export default ShopperTabBar
