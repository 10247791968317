import React from 'react'
import { IconVariant } from '@shopper/app/features/home/components/utils/constants'
import { XStack } from '@shopper/ui/src'
import { CaptionBoldText } from '@shopper/ui/src/components/text/new'
import { ChevronRight, RefreshCcw, ShoppingCart } from '@tamagui/lucide-icons'

interface ActionButtonProps {
  copy: string
  iconVariant: IconVariant
  onPress: () => void
}

export const ActionButton: React.FC<ActionButtonProps> = ({ copy, iconVariant, onPress }) => {
  return (
    <XStack
      onPress={onPress}
      backgroundColor="$opaquePurple"
      hoverStyle={{ backgroundColor: '$purpleHover', cursor: 'pointer' }}
      gap={5}
      borderRadius={7}
      paddingVertical={6}
      paddingHorizontal={10}
      alignItems="center"
    >
      <CaptionBoldText color="$baseWhite">{copy}</CaptionBoldText>
      {iconVariant === IconVariant.ARROW && (
        <ChevronRight size={15} strokeWidth={3} color="$baseWhite" />
      )}
      {iconVariant === IconVariant.RETRY && (
        <RefreshCcw size={15} strokeWidth={3} color="$baseWhite" />
      )}
      {iconVariant === IconVariant.CART && (
        <ShoppingCart size={15} strokeWidth={3} color="$baseWhite" />
      )}
    </XStack>
  )
}
