import * as React from 'react'
import Svg, { Circle, type SvgProps } from 'react-native-svg'

export const MilestoneCircle: React.FC<SvgProps> = (props) => {
  return (
    <Svg fill="none" {...props}>
      <Circle cx={6} cy={6} r={6} fill="#CE7813" />
      <Circle cx={5.96791} cy={5.9684} r={4.94496} fill="#E8A510" />
      <Circle cx={5.96815} cy={5.96815} r={3.75135} fill="#FFCC59" />
    </Svg>
  )
}
