export const STEPPER_BANNER_URL =
  'https://odcmbzocropvzmbsarer.supabase.co/storage/v1/object/public/product-images/stepper_vector_price_chop2x.png'

export const FREE_PRODUCTS_BANNER_URL =
  'https://odcmbzocropvzmbsarer.supabase.co/storage/v1/object/public/product-images/image%201758%20(1).png'

export const SAMSAM_LOGO_URL =
  'https://odcmbzocropvzmbsarer.supabase.co/storage/v1/object/public/product-images/Component%2075.png'

export const STEP_INDICATOR_SIZE = 24

export const PLAY_STORE_REFERRER_PREFIX = 'pricechop'

export const PRICE_CHOP_PRODUCTS_LIST_ID = 'Priceshop_samsam'

export const PRICE_CHOP_DEBUG_PREFIX = '[PRICE-CHOP]'

export const PRICE_CHOP_HOURS_UNTIL_EXPIRY = 24
