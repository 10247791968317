import React from 'react'
import BaseGrid from '@shopper/ui/src/components/BaseGrid'
import { BaseSkeleton } from '@shopper/ui/src/components/BaseSkeleton'
import { useWindowDimensions } from 'tamagui'

const SubhomeSkeleton: React.FC = () => {
  const { width, height } = useWindowDimensions()

  return (
    <BaseGrid>
      <BaseSkeleton width={width * 0.95} height={height * 0.2} />
      <BaseSkeleton width={width * 0.95} height={height * 0.2} />
      <BaseGrid.Row>
        <BaseGrid.Column>
          <BaseSkeleton width={width * 0.45} height={311} />
          <BaseSkeleton width={width * 0.45} height={311} />
        </BaseGrid.Column>
        <BaseGrid.Column>
          <BaseSkeleton width={width * 0.45} height={311} />
          <BaseSkeleton width={width * 0.45} height={311} />
        </BaseGrid.Column>
      </BaseGrid.Row>
    </BaseGrid>
  )
}

export default SubhomeSkeleton
