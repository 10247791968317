import React from 'react'
import { FreeProductsSectionCard } from '@shopper/app/features/home/components/FreeProductsSection/Card'
import { FreeProductsSectionFrame } from '@shopper/app/features/home/components/FreeProductsSection/Frame'
import { FreeProductsSectionHeader } from '@shopper/app/features/home/components/FreeProductsSection/Header'
import { IconVariant } from '@shopper/app/features/home/components/utils/constants'
import { XStack, YStack } from '@shopper/ui/src'
import { CaptionStandardText } from '@shopper/ui/src/components/text/new'

interface GameLostComponentViewProps {
  name: string
  imageUrl: string
  price: number
  remainingInvitations: number
  onPress: () => void
}

export const GameLostComponentView: React.FC<GameLostComponentViewProps> = ({
  name,
  imageUrl,
  price,
  remainingInvitations,
  onPress,
}) => {
  return (
    <FreeProductsSectionFrame onPress={onPress}>
      <FreeProductsSectionHeader
        copy="Intentar de nuevo"
        iconVariant={IconVariant.RETRY}
        onPress={onPress}
      />
      <XStack gap={10} alignItems="center">
        <FreeProductsSectionCard name={name} price={price} imageUrl={imageUrl} isDisabled />
        <YStack alignItems="center" justifyContent="center" flex={1}>
          <CaptionStandardText textAlign="center">
            ¡Pasaron 24 horas y te faltaron {remainingInvitations} invitaciones!
          </CaptionStandardText>
        </YStack>
      </XStack>
    </FreeProductsSectionFrame>
  )
}
