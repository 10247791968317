import React from 'react'
import { FreeProductsSectionCard } from '@shopper/app/features/home/components/FreeProductsSection/Card'
import { FreeProductsSectionFrame } from '@shopper/app/features/home/components/FreeProductsSection/Frame'
import { FreeProductsSectionHeader } from '@shopper/app/features/home/components/FreeProductsSection/Header'
import { FreeProductsSectionTimer } from '@shopper/app/features/home/components/FreeProductsSection/Timer'
import { IconVariant } from '@shopper/app/features/home/components/utils/constants'
import { XStack, YStack } from '@shopper/ui/src'
import { CaptionStandardText } from '@shopper/ui/src/components/text/new'
import { ProgressBar } from './ProgressBar'

interface GameOngoingComponentViewProps {
  hours: number
  minutes: number
  seconds: number
  name: string
  imageUrl: string
  price: number
  totalInvitations: number
  acceptedInvitations: number
  onPress: () => void
}

export const GameOngoingComponentView: React.FC<GameOngoingComponentViewProps> = ({
  hours,
  minutes,
  seconds,
  name,
  imageUrl,
  price,
  totalInvitations,
  acceptedInvitations,
  onPress,
}) => {
  return (
    <FreeProductsSectionFrame onPress={onPress}>
      <FreeProductsSectionHeader
        copy={totalInvitations === acceptedInvitations ? 'Reclamar producto' : 'Quiero ganar'}
        iconVariant={IconVariant.ARROW}
        onPress={onPress}
      />
      <XStack gap={10} alignItems="center">
        <FreeProductsSectionCard name={name} price={price} imageUrl={imageUrl} />
        <YStack gap={5} alignItems="center" flex={1}>
          <ProgressBar
            totalInvitations={totalInvitations}
            acceptedInvitations={acceptedInvitations}
          />
          {totalInvitations === acceptedInvitations ? (
            <CaptionStandardText>¡Ya tienes tu producto!</CaptionStandardText>
          ) : (
            <React.Fragment>
              <CaptionStandardText>
                ¡Te faltan {totalInvitations - acceptedInvitations} invitaciones! Complétalas en:
              </CaptionStandardText>
              <FreeProductsSectionTimer hours={hours} minutes={minutes} seconds={seconds} />
            </React.Fragment>
          )}
        </YStack>
      </XStack>
    </FreeProductsSectionFrame>
  )
}
