import React from 'react'
import type { IconVariant } from '@shopper/app/features/home/components/utils/constants'
import { XStack } from '@shopper/ui/src'
import { HeadingText3 } from '@shopper/ui/src/components/text/new'
import { ActionButton } from './ActionButton'

interface FreeProductsSectionHeaderProps {
  copy: string
  iconVariant: IconVariant
  onPress: () => void
}

export const FreeProductsSectionHeader: React.FC<FreeProductsSectionHeaderProps> = ({
  copy,
  onPress,
  iconVariant,
}) => {
  return (
    <XStack justifyContent="space-between" alignItems="center">
      <HeadingText3 color="$opaquePurple">Productos gratis</HeadingText3>
      <ActionButton copy={copy} iconVariant={iconVariant} onPress={onPress} />
    </XStack>
  )
}
