import React from 'react'
import { MainHome } from '@shopper/app/features/home/components/MainHome'
import { SubhomeScreenProvider } from '@shopper/app/features/home/context'
import { useCheckUnseenInvitation } from '@shopper/app/features/price-chop/hooks'

export const HomeScreen: React.FC = () => {
  useCheckUnseenInvitation()

  return (
    <SubhomeScreenProvider>
      <MainHome />
    </SubhomeScreenProvider>
  )
}
