import React from 'react'
import errorScreen from '@shopper/app/public/assets/errors/503.svg'
import useWindowDimensions from '@shopper/app/utils/hooks/useWindowDimensions'
import { XStack } from '@shopper/ui/src'
import { SolitoImage as Image } from 'solito/image'

export const UnderMaintenance = (): JSX.Element => {
  const { width, height } = useWindowDimensions()
  return (
    <XStack width="100%" position="relative" top={0} zIndex={999} justifyContent="center">
      <Image
        src={errorScreen}
        alt="Estamos en mantenimiento"
        width={width}
        height={height}
        contentPosition={{ top: 0 }}
      />
    </XStack>
  )
}
