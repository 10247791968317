import React, { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { SUBHOME_OPTION_GROUP } from '@shopper/app/features/home/constants'
import { SubhomeScreenContext } from '@shopper/app/features/home/context'
import type { SubhomeKey } from '@shopper/app/features/home/context/types'
import posthogClient from '@shopper/app/utils/services/analytics/posthog'
import { Button, ScrollView } from '@shopper/ui/src'
import { CaptionBoldText } from '@shopper/ui/src/components/text/new'

export const SubHomeBar: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const { changeScreen } = useContext(SubhomeScreenContext)
  const router = useRouter()
  const { tab } = router.query
  const handleOptionPress = (index: number, href: SubhomeKey): void => {
    setActiveIndex(index)
    changeScreen(href)
    posthogClient.captureCustomEvent('shopper_subhome_open', { categoryName: href })
    router.push({ query: { ...router.query, tab: index, sub_tab: 0 } })
  }

  useEffect(() => {
    if (tab && typeof tab === 'string') setActiveIndex(parseInt(tab))
    else return
  }, [tab])

  return (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={{
        alignItems: 'center',
        gap: 10,
      }}
      paddingTop={3}
      backgroundColor="$pureWhite"
    >
      {SUBHOME_OPTION_GROUP.map((option, index) => {
        const isActive = activeIndex === index
        const borderColor = isActive ? '#08356B' : 'transparent'
        const textColor = isActive ? '$neutral1000' : '#5A5555'
        const isOutlet = option.name === 'OUTLETS'

        return (
          <Button
            unstyled
            key={`${option.name}-subhome-bar`}
            borderWidth={0}
            borderBottomWidth={2}
            borderColor={borderColor}
            paddingHorizontal={10}
            paddingVertical={7.5}
            backgroundColor="$pureWhite"
            onPress={(): void => handleOptionPress(index, option.href)}
            focusStyle={{
              outlineStyle: 'none',
            }}
            focusVisibleStyle={{
              outlineStyle: 'none',
            }}
            hoverStyle={{
              backgroundColor: '$primary100',
            }}
          >
            {isOutlet ? (
              <CaptionBoldText
                color={isActive ? '$secondary500' : '$primary1000'}
                backgroundColor={isActive ? '$transparent' : '$amber'}
                paddingHorizontal={3}
                paddingVertical={1}
                borderTopLeftRadius={8}
                borderBottomLeftRadius={2}
                borderTopRightRadius={2}
                borderBottomRightRadius={8}
              >
                {option.name}
              </CaptionBoldText>
            ) : (
              <CaptionBoldText color={textColor}>{option.name}</CaptionBoldText>
            )}
          </Button>
        )
      })}
    </ScrollView>
  )
}
