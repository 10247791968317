import React from 'react'
import { USER_TESTING_IDS } from '@shopper/app/features/home/components/FreeProductsSection/constants'
import { useGetDiscountGame } from '@shopper/app/features/price-chop/hooks'
import { useSelector } from '@shopper/app/store'
import { selectUserId } from '@shopper/app/store/selectors'
import isNil from 'lodash/isNil'
import { ProductCarousel } from './Carousel'
import { GameLostComponent } from './GameLost'
import { GameOngoingComponent } from './GameOngoing'

export const FreeProductsSection: React.FC = () => {
  const userId = useSelector(selectUserId)

  const {
    data: discountGameQueryData,
    isError: isErrorDiscountGame,
    isLoading: isLoadingDiscountGame,
  } = useGetDiscountGame()

  if (!USER_TESTING_IDS.includes(userId ?? '')) {
    return null
  }

  if (isLoadingDiscountGame) {
    return null
  }

  if (isNil(discountGameQueryData) || isErrorDiscountGame) {
    return <ProductCarousel />
  }

  const { discountGame, remainingTimeInSeconds } = discountGameQueryData
  const {
    discountGame: { status: gameStatus },
  } = discountGame

  if (gameStatus === 'EXPIRED') {
    return <GameLostComponent discountGame={discountGame} />
  }

  if (gameStatus === 'ACTIVE') {
    return (
      <GameOngoingComponent discountGame={discountGame} remainingTime={remainingTimeInSeconds} />
    )
  }

  return <ProductCarousel />
}
