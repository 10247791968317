import React from 'react'
import { useLink } from 'solito/link'
import PATHS from '@centrito/common/paths'
import type { DiscountGamePublicComposite } from '@centrito/api/nest/shopper/promotions/price-chop/domain/composites/discount-game.composite'
import { GameLostComponentView } from './View'

interface GameLostComponentProps {
  discountGame: DiscountGamePublicComposite
}

export const GameLostComponent: React.FC<GameLostComponentProps> = ({ discountGame }) => {
  const { onPress } = useLink({ href: PATHS.PriceChop.Products })
  const { variant, product, invitations, discountGame: game } = discountGame
  const acceptedInvitations = invitations.length

  return (
    <GameLostComponentView
      name={product.product.name}
      imageUrl={variant.images[0].url}
      price={product.pricingData.priceTotal}
      remainingInvitations={game.totalInvitations - acceptedInvitations}
      onPress={onPress}
    />
  )
}
