import React, { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { SubhomeScreenContext } from '@shopper/app/features/home/context'
import { TabBarOptions } from '@shopper/app/features/home/context/types'
import posthogClient from '@shopper/app/utils/services/analytics/posthog'
import { ScrollView, XStack } from '@shopper/ui/src'
import { BodyText2 } from '@shopper/ui/src/components/text/new/body/BodyText2'
import { BodyText3 } from '@shopper/ui/src/components/text/new/body/BodyText3'

export const ProductListTabBar: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const { updateTabBarOption } = useContext(SubhomeScreenContext)
  const router = useRouter()
  const { sub_tab } = router.query
  const productListOptions = [
    { displayName: 'Explora', type: TabBarOptions.EXPLORE },
    { displayName: 'Recomendado', type: TabBarOptions.RECOMMENDED },
    { displayName: 'Más vendidos', type: TabBarOptions.POPULAR },
    { displayName: 'Ofertas', type: TabBarOptions.OFFER },
    { displayName: 'Últimas novedades', type: TabBarOptions.LATEST },
  ]
  const handleOptionPress = (tabBarOption: TabBarOptions, index: number): void => {
    setActiveIndex(index)
    updateTabBarOption(tabBarOption)
    router.push({ query: { ...router.query, sub_tab: index } })

    posthogClient.captureCustomEvent('shopper_web_product_list_tab_bar_press', {
      productListTabName: productListOptions[index].displayName,
    })
  }

  useEffect(() => {
    if (sub_tab && typeof sub_tab === 'string') setActiveIndex(parseInt(sub_tab))
    else return
  }, [sub_tab])

  return (
    <ScrollView
      horizontal
      backgroundColor={'white'}
      paddingTop={10}
      paddingBottom={3}
      showsHorizontalScrollIndicator={false}
      maxHeight={45}
      bounces={false}
      alwaysBounceHorizontal={false}
      disableIntervalMomentum
    >
      {productListOptions.map((option, index) => (
        <XStack
          key={`${option.displayName}-tab-bar`}
          paddingHorizontal={20}
          onPress={(): void => handleOptionPress(option.type, index)}
          borderBottomColor={index === activeIndex ? '$Gray1' : 'transparent'}
          borderBottomWidth={2}
        >
          {index === activeIndex ? (
            <BodyText2 paddingBottom={8}>{option.displayName}</BodyText2>
          ) : (
            <BodyText3>{option.displayName}</BodyText3>
          )}
        </XStack>
      ))}
    </ScrollView>
  )
}
