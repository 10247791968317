import { useEffect, useState } from 'react'

export const useTimer = (
  remainingTime: number,
): {
  hours: number
  minutes: number
  seconds: number
  isEnded: boolean
} => {
  const [timeLeft, setTimeLeft] = useState(remainingTime)
  const [isEnded, setIsEnded] = useState(false)

  useEffect(() => {
    if (timeLeft <= 0) {
      setIsEnded(true)
      return
    }

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          setIsEnded(true)
          clearInterval(intervalId)
          return 0
        }
        return prevTime - 1
      })
    }, 1000)

    return (): void => clearInterval(intervalId)
  }, [timeLeft])

  const hours = Math.floor(timeLeft / 3600)
  const minutes = Math.floor((timeLeft % 3600) / 60)
  const seconds = timeLeft % 60

  return {
    hours,
    minutes,
    seconds,
    isEnded,
  }
}
