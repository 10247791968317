import isNil from 'lodash.isnil'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import type { InvitationPublic } from '@centrito/api/nest/platform/database/domain'
import { seenInvitationsInitialState } from './initialState'
import { type SeenInvitationsStore } from './types'

export const useSeenInvitationsStore = create<SeenInvitationsStore>()(
  devtools(
    persist(
      immer((set, get) => ({
        ...seenInvitationsInitialState,
        resetInvitations: (): void => set(seenInvitationsInitialState),
        markAsSeen: (invitationId: string): void =>
          set(
            (state) => {
              if (!state.seenIds.includes(invitationId)) {
                state.seenIds.push(invitationId)
              }
            },
            undefined,
            'mark-as-seen',
          ),
        getFirstUnseenInvitation: (invitations: InvitationPublic[]): InvitationPublic | null => {
          const unseenInvitation = invitations.find(
            (invitation) => !get().seenIds.includes(invitation.id),
          )
          if (!isNil(unseenInvitation)) {
            get().markAsSeen(unseenInvitation.id)
            return unseenInvitation
          }
          return null
        },
        getAllUnseenInvitations: (invitations: InvitationPublic[]): InvitationPublic[] => {
          return invitations.filter((invitation) => !get().seenIds.includes(invitation.id))
        },
      })),
      {
        name: 'price-chop-seen-invitations',
      },
    ),
  ),
)
