import { useEffect } from 'react'
import type { RouterOutputs } from '@shopper/app/api/trpc'
import { trpc } from '@shopper/app/api/trpc'
import { usePriceChopJourneyStore } from '@shopper/app/features/price-chop/contexts/journey'
import { useShallow } from 'zustand/react/shallow'

export type DiscountGameTrpcData = RouterOutputs['promotions']['priceChop']['getDiscountGame']

export const useGetDiscountGame = (): {
  data: DiscountGameTrpcData | undefined
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
} => {
  const { isProductRedeemed, setIsProductRedeemed } = usePriceChopJourneyStore(
    useShallow((state) => ({
      isProductRedeemed: state.isProductRedeemed,
      setIsProductRedeemed: state.setIsProductRedeemed,
    })),
  )

  useEffect(() => {
    if (!isProductRedeemed) {
      setIsProductRedeemed(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return trpc.promotions.priceChop.getDiscountGame.useQuery(undefined, {
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
    retry: false,
    throwOnError: false,
  })
}
