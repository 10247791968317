import React from 'react'
import { ContentStandardText } from '@shopper/ui/src/components/text/new'
import { Link } from 'solito/link'
import { XStack } from 'tamagui'

export interface TextIconButtonProps {
  href: string
  text: string
  textColor?: string
  icon: React.ReactNode
  onPress?: () => void
}

export const TextIconButton: React.FC<TextIconButtonProps> = ({
  href,
  text,
  textColor = '$brightRed',
  icon,
  onPress,
}) => {
  return (
    <Link href={href}>
      <XStack
        paddingHorizontal={8}
        paddingVertical={5}
        onPress={onPress}
        alignItems="center"
        gap={3}
      >
        <ContentStandardText color={textColor}>{text}</ContentStandardText>

        {icon}
      </XStack>
    </Link>
  )
}
