import React from 'react'
import { MilestoneCircle } from '@shopper/app/features/price-chop/atoms/svg/milestone-circle/MilestoneCircle'
import { Circle, XStack } from '@shopper/ui/src'

interface ProgressBarProps {
  totalInvitations: number
  acceptedInvitations: number
}

const CIRCLE_SIZE = 12

export const ProgressBar: React.FC<ProgressBarProps> = ({
  totalInvitations,
  acceptedInvitations,
}) => {
  return (
    <XStack width="100%" alignItems="center">
      {Array.from({ length: totalInvitations }).map((_, index) => {
        if (index < acceptedInvitations) {
          return (
            <XStack key={`accepted-${index}`} alignItems="center" flex={1}>
              <XStack
                flex={1}
                height={4}
                backgroundColor="#FFCC59"
                borderBottomWidth={1}
                borderBottomColor="#CE7813"
                {...(index === 0 && { borderBottomLeftRadius: 100, borderTopLeftRadius: 100 })}
              />
              <MilestoneCircle width={CIRCLE_SIZE} height={CIRCLE_SIZE} />
            </XStack>
          )
        }

        return (
          <XStack key={`pending-${index}`} alignItems="center" flex={1}>
            <XStack
              flex={1}
              height={4}
              backgroundColor="#E7E7E7"
              {...(index === 0 && { borderBottomLeftRadius: 100, borderTopLeftRadius: 100 })}
            />
            <Circle size={CIRCLE_SIZE} backgroundColor="#E7E7E7" />
          </XStack>
        )
      })}
    </XStack>
  )
}
