import React, { memo } from 'react'
import { BannerPlatform } from '@prisma/client/react-native'
import { OutletsGrid } from '@shopper/app/components/OutletsGrid'
import { BrandScrollView } from '@shopper/app/features/home/components/BrandScrollView'
import { CategoryDisplay } from '@shopper/app/features/home/components/CategoryDisplay'
import { CouponBanner } from '@shopper/app/features/home/components/CouponBanner'
import { FreeProductsSection } from '@shopper/app/features/home/components/FreeProductsSection'
import { HomeBannerSlider } from '@shopper/app/features/home/components/HomeBannerSlider'
import { HomeValueProposalCards } from '@shopper/app/features/home/components/HomeValueProposalCards'
import { ProductListTabBar } from '@shopper/app/features/home/components/ProductListTabBar'
import SubhomeFeed from '@shopper/app/features/home/components/SubhomeFeed'
import { SubhomeKey } from '@shopper/app/features/home/context/types'
import MainHomeTabBarLayout from '@shopper/app/layouts/MainHomeTabBarLayout'
import { Stack } from '@shopper/ui/src'
import { SubtitleText3 } from '@shopper/ui/src/components/text/new/subtitles/SubtitleText3'
import { LinearGradient } from '@tamagui/linear-gradient'

interface SubhomeProps {
  subhome: string
}

const MemoizedSubhome = memo(SubhomeFeed)

const Subhome: React.FC<SubhomeProps> = ({ subhome }) => {
  const isHome = subhome === '/'

  if (isHome) {
    return (
      <MainHomeTabBarLayout isFeedView={false} isHome>
        <HomeBannerSlider platform={BannerPlatform.WEB} subhome={subhome} />
        <CouponBanner subhome={subhome} />
        <HomeValueProposalCards />
        <FreeProductsSection />
        <CategoryDisplay subhome={subhome} />
        <ProductListTabBar />
        <Stack>
          <MemoizedSubhome isMainHome isShowingFilters={false} />
        </Stack>
      </MainHomeTabBarLayout>
    )
  }

  return (
    <MainHomeTabBarLayout isFeedView={false}>
      <HomeBannerSlider platform={BannerPlatform.WEB} subhome={subhome} />
      {subhome !== SubhomeKey.OUTLETS && <CategoryDisplay subhome={subhome} />}
      {subhome === SubhomeKey.BELLEZA && <BrandScrollView />}
      {subhome === SubhomeKey.OUTLETS && <OutletsGrid />}
      <LinearGradient
        borderRadius={20}
        width="100%"
        colors={['$samsamBlueTranslucent10', '$Gray4']}
        end={{ x: 1, y: 0.05 }}
      >
        <Stack width="100%" alignItems="center">
          <SubtitleText3 paddingTop={10}>Para ti</SubtitleText3>
        </Stack>
        <SubhomeFeed isShowingFilters={false} isMainHome={isHome} />
      </LinearGradient>
    </MainHomeTabBarLayout>
  )
}

export default React.memo(Subhome)
