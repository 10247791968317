import React from 'react'
import { formatPrice } from '@shopper/app/utils/products'
import { XStack, YStack, ZStack } from '@shopper/ui/src'
import {
  CaptionStandardText,
  DetailText3,
  FeatureBoldText,
} from '@shopper/ui/src/components/text/new'
import { Platform } from 'react-native'
import { SolitoImage as Image } from 'solito/image'

const IMAGE_SIZE = 80
const CONTAINER_WIDTH = 80
const CONTAINER_HEIGHT = 95
const BORDER_RADIUS = 8

interface FreeProductsSectionCardProps {
  name: string
  price: number
  imageUrl: string
  isDisabled?: boolean
}

export const FreeProductsSectionCard: React.FC<FreeProductsSectionCardProps> = ({
  name,
  price,
  imageUrl,
  isDisabled = false,
}) => {
  return (
    <ZStack width={CONTAINER_WIDTH} height={CONTAINER_HEIGHT}>
      <YStack width={CONTAINER_WIDTH} height={CONTAINER_HEIGHT}>
        <Image
          width={IMAGE_SIZE}
          height={IMAGE_SIZE}
          src={imageUrl}
          alt="Free product promotional item from rewards catalog"
          style={{
            borderRadius: BORDER_RADIUS,
            ...(Platform.OS === 'web'
              ? {
                  filter: isDisabled ? 'grayscale(100%)' : 'none',
                }
              : {
                  opacity: isDisabled ? 0.5 : 1,
                }),
          }}
          contentFit="fill"
        />
      </YStack>
      <YStack
        justifyContent="flex-end"
        width={CONTAINER_WIDTH}
        height={CONTAINER_HEIGHT}
        borderColor="$neutral300"
        borderWidth={2}
        borderRadius={BORDER_RADIUS}
      >
        <YStack
          backgroundColor="white"
          height="fit-content"
          paddingVertical={3}
          paddingHorizontal={5}
          borderRadius={BORDER_RADIUS}
        >
          <CaptionStandardText numberOfLines={1} color="$neutral1000">
            {name}
          </CaptionStandardText>
          <XStack backgroundColor="white" alignItems="center" justifyContent="space-around" gap={3}>
            <FeatureBoldText color={isDisabled ? '$neutral500' : '$samsamOrange'}>
              $0
            </FeatureBoldText>
            <DetailText3 textDecorationLine="line-through" color="$neutral700">
              {formatPrice(price)}
            </DetailText3>
          </XStack>
        </YStack>
      </YStack>
    </ZStack>
  )
}
