import React from 'react'
import { XStack } from '@shopper/ui/src'

interface HomeValueProposalCardProps {
  Icon: React.ReactNode
  Content: React.ReactNode
  width: number
  onPress: () => void
}

export const HomeValueProposalCard: React.FC<HomeValueProposalCardProps> = ({
  Icon,
  Content,
  width,
  onPress,
}) => {
  return (
    <XStack
      alignItems="center"
      backgroundColor="$pureWhite"
      paddingVertical={6}
      paddingHorizontal={15}
      gap={10}
      minHeight={50}
      width={width}
      borderRadius={8}
      cursor="pointer"
      onPress={(): void => onPress()}
    >
      {Icon}
      {Content}
    </XStack>
  )
}
