import { useEffect } from 'react'
import { useSeenInvitationsStore } from '@shopper/app/features/price-chop/contexts'
import { useGetDiscountGame } from '@shopper/app/features/price-chop/hooks'
import isNil from 'lodash.isnil'
import { useRouter } from 'solito/router'
import PATHS from '@centrito/common/paths'

export const useCheckUnseenInvitation = (): void => {
  const router = useRouter()
  const { getAllUnseenInvitations } = useSeenInvitationsStore()
  const { data } = useGetDiscountGame()

  useEffect(() => {
    if (!isNil(data)) {
      const unseenInvitations = getAllUnseenInvitations(data.discountGame.invitations)

      if (unseenInvitations.length > 0 && data.discountGame.discountGame.status === 'ACTIVE') {
        router.push(PATHS.PriceChop.Share)
      }
    }
  }, [data, getAllUnseenInvitations, router])
}
