import React from 'react'
import { useTimer } from '@shopper/app/features/price-chop/hooks'
import { useLink } from 'solito/link'
import PATHS from '@centrito/common/paths'
import type { DiscountGamePublicComposite } from '@centrito/api/nest/shopper/promotions/price-chop/domain/composites/discount-game.composite'
import { GameOngoingComponentView } from './View'

interface GameOngoingComponentProps {
  discountGame: DiscountGamePublicComposite
  remainingTime: number
}

export const GameOngoingComponent: React.FC<GameOngoingComponentProps> = ({
  discountGame,
  remainingTime,
}) => {
  const { variant, product, invitations, discountGame: game } = discountGame
  const { hours, minutes, seconds } = useTimer(remainingTime)

  const acceptedInvitations = invitations.length

  const { onPress } = useLink({ href: PATHS.PriceChop.Share })

  return (
    <GameOngoingComponentView
      name={product.product.name}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
      imageUrl={variant.images[0].url}
      price={product.pricingData.priceTotal}
      totalInvitations={game.totalInvitations}
      acceptedInvitations={acceptedInvitations}
      onPress={onPress}
    />
  )
}
