import React from 'react'
import { XStack, YStack } from '@shopper/ui/src'
import { FeatureBoldText, FootnoteStandardText } from '@shopper/ui/src/components/text/new'

interface FreeProductsDisplayTimerProps {
  hours: number
  minutes: number
  seconds: number
}

export const FreeProductsSectionTimer: React.FC<FreeProductsDisplayTimerProps> = ({
  hours,
  minutes,
  seconds,
}) => {
  return (
    <XStack width="fit-content" gap={5} justifyContent="space-between">
      <YStack alignItems="center">
        <FeatureBoldText color="#0A0A0B">{hours}</FeatureBoldText>
        <FootnoteStandardText color="#858585">Horas</FootnoteStandardText>
      </YStack>
      <FeatureBoldText color="#0A0A0B">:</FeatureBoldText>
      <YStack alignItems="center">
        <FeatureBoldText color="#0A0A0B">{minutes}</FeatureBoldText>
        <FootnoteStandardText color="#858585">Minutos</FootnoteStandardText>
      </YStack>
      <FeatureBoldText color="#0A0A0B">:</FeatureBoldText>
      <YStack alignItems="center">
        <FeatureBoldText color="#0A0A0B">{seconds}</FeatureBoldText>
        <FootnoteStandardText color="#858585">Segundos</FootnoteStandardText>
      </YStack>
    </XStack>
  )
}
