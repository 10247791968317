import React from 'react'
import { trpc } from '@shopper/app/api/trpc'
import { PRICE_CHOP_PRODUCTS_LIST_ID } from '@shopper/app/features/price-chop/constants'
import isNil from 'lodash.isnil'
import { useLink } from 'solito/link'
import PATHS from '@centrito/common/paths'
import { GetProductsFilterType as FilterType } from '@centrito/api/shared/enums'
import { ProductCarouselView } from './View'

export const ProductCarousel: React.FC = () => {
  const { data } = trpc.catalog.product.findMany.useQuery({
    filters: { [FilterType.LIST_ID]: PRICE_CHOP_PRODUCTS_LIST_ID },
    cursor: 0,
  })

  const { onPress } = useLink({ href: PATHS.PriceChop.Products })

  if (isNil(data)) {
    return null
  }

  const { products } = data

  return (
    <ProductCarouselView
      products={products.slice(0, 9).sort(() => Math.random() - 0.5)}
      onPress={onPress}
    />
  )
}
