import React from 'react'
import { FreeProductsSectionCard } from '@shopper/app/features/home/components/FreeProductsSection/Card'
import { FreeProductsSectionFrame } from '@shopper/app/features/home/components/FreeProductsSection/Frame'
import { FreeProductsSectionHeader } from '@shopper/app/features/home/components/FreeProductsSection/Header'
import { IconVariant } from '@shopper/app/features/home/components/utils/constants'
import { ScrollView, XStack } from '@shopper/ui/src'
import type { ProductCompositePublic } from '@centrito/api/nest/shopper/catalog/products/domain/composites'

interface ProductCarouselViewProps {
  products: ProductCompositePublic[]
  onPress: () => void
}

export const ProductCarouselView: React.FC<ProductCarouselViewProps> = ({ products, onPress }) => {
  return (
    <FreeProductsSectionFrame onPress={onPress}>
      <FreeProductsSectionHeader
        copy="Quiero ganar"
        iconVariant={IconVariant.ARROW}
        onPress={onPress}
      />
      <ScrollView horizontal>
        <XStack flex={1} gap={5}>
          {products.map((product) => (
            <FreeProductsSectionCard
              key={product.product.id}
              name={product.product.name}
              price={product.pricingData.priceRetail}
              imageUrl={product.variants[0].images[0].url}
            />
          ))}
        </XStack>
      </ScrollView>
    </FreeProductsSectionFrame>
  )
}
